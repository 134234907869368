import React from 'react';
import '../../../assets/css/footer.css';

const Footer = () => (
  <footer className="footer">
    <div className="bottom-bar clearfix">
      <div className="footer-container h-100 py-1">
        <div className="row justify-content-center align-items-center h-100">
          <p className="latoBold mb-1 copy-right">Copyright 2022 Tintash - All Rights Reserved</p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
