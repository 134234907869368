import React, {useState} from 'react';
import * as classes from './PdfSection.module.css';

const handleDownload = async (filename, downloadFile) => {
  const link = document.createElement('a');
  link.href = `/pdf/${filename}.pdf`;
  link.download = `${downloadFile}.pdf`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const PDFSection = ({filename, image, downloadFileName}) => {
  const [form, setForm] = useState({
    fullname: '',
    email: '',
  });

  const handleInputChange = e => {
    const {name, value} = e.target;
    setForm(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const body = JSON.stringify({
      fullname: form.fullname,
      email: form.email,
    });

    try {
      const response = await fetch('https://hook.eu1.make.com/15ongveh1tvccwm9ecm4njb78l8krh2n', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body,
      });
      if (response.ok) {
        await handleDownload(filename, downloadFileName);
      } else {
        throw new Error('Failed to send data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className={classes.wrapper}>
      <img className={classes.banner} src={image} alt="img" />
      {/* <div className={classes.formContainer}> */}
      <form className={classes.form} onSubmit={handleSubmit}>
        <input
          className={classes.inputContainer}
          type="text"
          id="fullname"
          name="fullname"
          placeholder="Full name*"
          value={form.fullname}
          onChange={handleInputChange}
          required
        />

        <input
          className={classes.inputContainer}
          type="email"
          id="email"
          name="email"
          placeholder="Email address*"
          value={form.email}
          onChange={handleInputChange}
          required
        />

        <button type="submit" disabled={!form.fullname || !form.email} className={classes.button}>
          Download PDF
        </button>
        <p className={classes.disclaimer}>
          By signing up you are agreeing to our{' '}
          <a
            href="https://tintash.com/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.privacyLink}
          >
            privacy policy
          </a>{' '}
          and providing consent to process this data for follow-up.
        </p>
      </form>
      {/* </div> */}
    </div>
  );
};

export default PDFSection;
